import React from "react";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <Intro title="Pagina non trovata"></Intro>
    </Layout>
  );
};

export default NotFoundPage;
